/**=====================
    28. typahead-search  CSS Start
==========================**/
.Typeahead {
  width: 100%;
  * {
    box-sizing: border-box;
    &:before {
      box-sizing: border-box;
    }
    &:after {
      box-sizing: border-box;
    }
  }
  .tt-hint {
    display: none !important;
  }
}
.u-cf {
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}
.u-hidden {
  display: none !important;
}
.u-posRelative {
  position: relative;
}
.Typeahead-spinner {
  position: absolute;
  top: 14px;
  right: 18px;
  display: none;
  color: #c5c5c5;
}
.ProfileCard-avatar {
  svg {
    height: 18px;
    vertical-align: -2px !important;
    path {
      color: #7e7c7c !important;
    }
    polygon {
      color: #7e7c7c !important;
    }
  }
}
.Typeahead-hint {
  width: 100%;
  padding: 5px 8px;
  font-size: 24px;
  line-height: 30px;
  border: 1px solid #024e6a;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ccd6dd;
  opacity: 1;
}
.Typeahead-menu {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px;
  z-index: 100;
  max-height: 308px;
  display: none;
  width: 100%;
  overflow: scroll;
  background-color: $white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}
.Typeahead-menu.is-open {
  display: block;
}
.Typeahead-selectable {
  cursor: pointer;
  + {
    .Typeahead-selectable {
      border-top: 1px solid #ccd6dd;
    }
  }
}
.ProfileCard {
  position: relative;
  padding: 4px;
  border-radius: 10px;
  &:hover {
    .ProfileCard-avatar {
      border-color: #f5f8fa;
    }
    .ProfileCard-screenName {
      color: $white;
    }
    .ProfileCard-stat-label {
      color: $white;
    }
  }
}
.ProfileCard-avatar {
  position: absolute;
  border-radius: 5px;
}
.ProfileCard-details {
  padding-left: 35px;
  float: left;
  width: 100%;
}
.ProfileCard-realName {
  display: flex;
  justify-content: space-between;
  color: $dark-gray;
  .realname{
    text-align: left;
    color: $dark;
    ~ span{
      a{
        color: $dark;
      }
    }
  }
}
.starred{
  color: $warning-color;
}
.ProfileCard-screenName {
  display: inline-block;
  color: $dark-gray;
}
.ProfileCard-description {
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
}
.ProfileCard-stats {
  float: right;
  text-align: right;
}
.ProfileCard-stat {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  + {
    .ProfileCard-stat {
      margin-left: 5px;
    }
  }
}
.ProfileCard-stat-label {
  color: $dark-gray;
  font-weight: 500;
}
.ProfileCard.is-active {
  color: $white;
  background: var(--theme-default);
  .ProfileCard-screenName {
    color: $white;
  }
  .ProfileCard-stat-label {
    color: $white;
  }
}
.EmptyMessage {
  position: relative;
  padding: 10px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}
.Typeahead-spinner {
  width: 1rem;
  height: 1rem;
}
/**=====================
    28. typahead-search CSS Ends
==========================**/