/**=====================
     15. List CSS Start
==========================**/
.list-group-item.active {
  background-color: var(--theme-default);
  border-color: var(--theme-default);
}
.list-group {
  i {
    margin-right: $list-group-margin;
  }
  img {
    height: 40px;
    width: 40px;
    margin-right: $list-group-margin;
  }
}
.listings{
  small{
    font-size: .875em;
  }
  ul.list-group{
    .list-group-item-action{
      color: #495057;
      img.media-body{
        width: 40px;
        height: 40px;
      }
      i{
        margin-right: 10px;
      }
      &.active{
        background-color: $primary-color;
        border-color: $primary-color;
        color: $white;
      }
    }
    .list-group-item.d-flex{
      color: $dark;
      .badge{
        padding: 4px 6px 3px;
      }
    }
  }
}
/**=====================
     15. List CSS Ends
==========================**/